import { isMobile } from '@/utils'
export default {
  address: '高雄市鼓山區美術東二路592號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.7131137718425!2d120.28663631538993!3d22.664482585135616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e051da8dd6a9f%3A0x303565ca3b3e6c56!2z576O6KGT55qH5bGF!5e0!3m2!1szh-TW!2stw!4v1594724565155!5m2!1szh-TW!2stw',
  googleLink: 'https://g.page/palace-of-modern-arts',
  phone: '07 554 6333',
  fbLink: 'https://www.facebook.com/palace.of.modern.arts/',
  fbMessage: 'http://m.me/palace.of.modern.arts/',
  caseName: '美術皇居',
  indigatorLength: 10,

  houseInfos: [
    ['投資建設', '京城建設'],
    ['營造公司', '建誌營造股份有限公司'],
    ['建築設計', '林子森林伯諭聯合建築師事務所'],
    ['基地面積', '3008坪'],
    ['公設比例', '31.0%'],
    ['建材說明', '中空樓板、美國EPS制震系統'],
    ['建造執照', '(102)高市工建築字第01576號'],
    ['使用執照', '(107)高市工建築使字第02299號'],
  ],

  gtmCode: ['NTW9BQH'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}